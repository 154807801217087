<template>
  <wz-table
    ref="areaGrid"
    auto-resize
    height="auto"
    v-loading="loading"
    :data="tableData"
    @checkbox-all="selectAllEvent"
    @checkbox-change="selectChangeEvent"
    @cell-dblclick="cellDBLClickEvent">
    <vxe-table-column type="checkbox" width="60"/>
    <vxe-table-column type="seq" title="序号" width="60"/>
    <vxe-table-column field="pointName" title="兴趣点名称" min-width="150"/>
    <vxe-table-column field="pointTypeName" title="兴趣点类型" width="100" />
    <vxe-table-column field="picType" title="图标" width="100">
      <template v-slot="{row}">
        <div class="flex align-center justify-center">
          <img class="w-24" :src="row.pointIcon">
        </div>
      </template>
    </vxe-table-column>
    <vxe-table-column field="radius" title="半径(米)" width="100"/>
    <vxe-table-column field="buildUnit" title="施工单位" min-width="150"/>
    <vxe-table-column field="roadName" title="所在道路" min-width="150"/>
    <vxe-table-column field="opertime" title="创建时间" width="150"/>
  </wz-table>
  <!-- <wz-table
    height="auto"
    auto-resize
    ref="poiGrid"
    :data="tablePointData"
    @cell-dblclick="cellDBLClickEvent"
    @checkbox-all="selectAllEvent"
    @checkbox-change="selectChangeEvent"
  >
    <vxe-table-column type="checkbox" width="60"/>
    <vxe-table-column type="seq" title="序号" width="60"/>
    <vxe-table-column field="pointName" title="兴趣点名称" min-width="150" show-overflow="title" />
    <vxe-table-column field="pointType" title="兴趣点类型" min-width="95" show-overflow="title" :formatter="formatterPointType"/>
    <vxe-table-column field="picType" title="图标" min-width="95">
      <template v-slot="{ row }">
        <div class="flex justify-center align-center">
          <img :src="picTypes[row.picType].img" style="width: 24px;height: 24px">
        </div>
      </template>
    </vxe-table-column>
    <vxe-table-column field="radius" title="半径(米)" min-width="95" show-overflow="title" />
    <vxe-table-column field="buildUnit" title="施工单位" min-width="150" show-overflow="title" />
    <vxe-table-column field="roadName" title="所在道路" min-width="150" show-overflow="title" />
    <vxe-table-column field="opertime" title="创建时间" min-width="150" show-overflow="title" />
  </wz-table> -->
</template>

<script>
import { formatLngLat } from '@/components/BaseMap/util'
import * as maptalks from 'maptalks'
import { getPoints, delPoint } from '@/apis/monitor/interestPoint'
import { pointTypes, picTypes } from '@/views/monitor/interestPoint/util'
export default {
  name: 'BasePoint',
  data () {
    return {
      pointTypes,
      picTypes,
      loading: false,
      mapIns: null,
      layerType: '',
      tableData: [],
      tableDataMap: new Map(),
      markers: [],
      markersMap: new Map(),
      editVisible: false,
      editData: null,
      markerTemp: null, // 临时的marker
      textCanvas: null, // 计算文字宽度
      isDrawing: false
    }
  },
  created () {
    window.closeInfoWinPoint = (pointId) => {
      const markerIns = this.markersMap.get(pointId)
      if (markerIns) {
        markerIns.closeInfoWindow()
      }
    }
  },
  beforeDestroy () {
    delete window.closeInfoWinPoint
  },
  watch: {
    editVisible (visible) {
      if (!visible) {
        this.clusterLayer.removeGeometry(this.markerTemp)
      }
    }
  },
  // mounted () {

  // },
  methods: {
    selectAllEvent ({ checked, records }) {
      this.showPointMarker(records)
    },
    selectChangeEvent ({ checked, records }) {
      this.showPointMarker(records)
    },
    showPointMarker (records) {
      this.markersMap.forEach((value) => {
        value.hide()
      })
      records.forEach(row => {
        const marker = this.markersMap.get(row.pointId)
        if (marker) {
          marker.show()
        }
      })
    },
    init (mapIns, layerType) {
      // const { mapIns, layerType } = this.$refs.myMap.init()
      this.mapIns = mapIns
      this.layerType = layerType
      this.clusterLayer = new maptalks.VectorLayer('vector', null, {
        zIndex: 1
      }).addTo(mapIns)

      this.initList()
      mapIns.on('click', ({ coordinate }) => {
        if (this.isDrawing) {
          const position = formatLngLat([coordinate.x, coordinate.y], this.layerType, 'gps')
          console.log(position, 'position')
          const markerConfig = {
            id: undefined,
            name: '',
            lon: position[0],
            lng: position[0],
            lat: position[1],
            pointIcon: require('@/assets/imgs/monitor/interestPoint/point_fz.png')
          }
          this.editData = markerConfig
          this.editVisible = true
          const markerIns = this.markerTemp = this.getMarkerIns(markerConfig)

          this.clusterLayer.addGeometry(markerIns)
          this.isDrawing = false
        }
      })
    },
    handlerAddPoint () {
      this.isDrawing = true
    },

    handlerMapLayerChange ({ mapIns, layerType }) {
      this.markers.forEach((marker) => {
        const properties = marker.getProperties()
        const position = formatLngLat(
          [properties.lng, properties.lat],
          'gps',
          layerType
        )
        marker.setCoordinates(position)
      })
    },

    async initList () {
      this.loading = true
      const res = await getPoints()
      this.loading = false
      if (res.code === 200) {
        const markers = this.markers = []
        const markersMap = this.markersMap = new Map()
        const tableData = this.tableData = []
        const tableDataMap = this.tableDataMap = new Map()
        const pointTypes = this.pointTypes
        const picTypes = this.picTypes

        res.data.forEach((item) => {
          const findTarget = pointTypes.find((subItem) => subItem.value === item.pointType) || { label: '未知' }
          item.pointTypeName = findTarget.label
          const findPicTarget = picTypes.find((subItem) => subItem.value === item.picType) || { img: '' }
          item.pointIcon = findPicTarget.img
          item.lng = item.lon
          const busNode = item

          // 创建marker
          const markerIns = this.getMarkerIns(busNode)
          // 创建marker window
          this.initMarkerWindow(markerIns, item)

          markers.push(markerIns)
          markersMap.set(busNode.pointId, markerIns)
          tableData.push(busNode)
          tableDataMap.set(busNode.pointId, busNode)
        })

        // 创建markers图层添加到地图中
        this.createMarkersLayerToMap(markers, this.mapIns)
        setTimeout(() => {
          this.$refs.areaGrid.$refs.innerTable.setAllCheckboxRow(true)
        })
      } else {
        this.$message.error(res.msg)
      }
    },

    // 创建marker图层添加到地图实例中
    createMarkersLayerToMap (markers) {
      this.clusterLayer.clear()
      this.clusterLayer.addGeometry(markers)
    },

    // 获取marker实例
    getMarkerIns (node) {
      const lngLat = formatLngLat(
        [node.lng, node.lat],
        'gps',
        this.layerType
      )
      return new maptalks.Marker(lngLat, {
        id: node.pointId, // 唯一标识 pointId
        properties: {
          name: node.pointName,
          lng: node.lng,
          lat: node.lat
        },
        symbol: [
          {
            markerFile: node.pointIcon, // marker 图标
            markerWidth: 25,
            markerHeight: 25,
            markerHorizontalAlignment: 'middle',
            markerVerticalAlignment: 'top'
          },
          {
            textFaceName: 'sans-serif',
            textName: '{name}',
            textSize: 12,
            textDy: 12,
            textFill: '#000000',
            markerType: 'square',
            markerFill: 'rgb(255,255,255)',
            markerFillOpacity: 1,
            markerLineColor: 'red',
            markerLineWidth: 0.8,
            markerWidth: this.getTextWidth(node.pointName) + 5,
            markerHeight: 17,
            markerDy: 12,
            markerRotation: 0
          }
        ]
      })
    },

    initMarkerWindow (markerIns, node) {
      const infoWindow = new maptalks.ui.InfoWindow({
        autoPan: false,
        autoOpenOn: 'click', // set to null if not to open when clicking on marker
        custom: true,
        width: 300,
        height: 300,
        content: this.getMarkerWindowContent(node)
      })
      markerIns.setInfoWindow(infoWindow)
      markerIns.on('click', () => {
        this.activeMarkerId = markerIns.getId()
        const row = this.tableDataMap.get(markerIns.getId())
        this.$refs.areaGrid.$refs.innerTable.scrollToRow(row)
        this.$refs.areaGrid.$refs.innerTable.setCurrentRow(row)
        markerIns.bringToFront()
        markerIns.getMap().panTo(markerIns.getCoordinates())
      })
    },

    // 获取marker窗口内容
    getMarkerWindowContent (node) {
      const html = `
            <div class="marker-card">
              <i class="marker-card-close el-icon-close fs-14" onclick="closeInfoWinPoint(${node.pointId})"></i>
              <!-- 字段 -->
              <div class="flex flex-column fs-12 bgcolor-white w-100-p">
                <div class="flex align-center fs-12 mt-2 mb-2">兴趣点名称: ${node.pointName}</div>
                <div class="flex align-center fs-12 mt-2 mb-2">类型: ${node.pointTypeName}</div>
                <div class="flex align-center fs-12 mt-2 mb-2">所在地址: ${(node.roadName ? node.roadName : '-')}</div>
                <div class="flex align-center fs-12 mt-2 mb-2">创建时间: ${node.opertime || '-'}</div>
              </div>
            </div>
          `.trim()
      return html
    },

    cellDBLClickEvent ({ row }) {
      const marker = this.markersMap.get(row.pointId)
      if (marker) {
        if (marker.getMap().getZoom() < 16) {
          marker.getMap().animateTo({
            center: marker.getCoordinates(),
            zoom: 16
          }, { duration: 500 })
        } else {
          marker.getMap().panTo(marker.getCoordinates())
        }
        marker.openInfoWindow()
      }
    },

    getTextWidth (text, font = 'normal 12px sans-serif') {
      const canvas = this.textCanvas || (this.textCanvas = document.createElement('canvas'))
      const context = canvas.getContext('2d')
      context.font = font
      return context.measureText(text).width
    },

    editRow (row) {
      this.editData = row
      this.editVisible = true
    },

    // 删除兴趣点
    deletetRow (row) {
      this.$confirm('删除兴趣点, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delPoint({ pointId: row.pointId }).then(res => {
          if (res.code === 200) {
            this.$message({ message: '删除成功', type: 'success' })
            this.initList()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    exportData () {
      this.$refs.areaGrid.$refs.innerTable.exportData({
        filename: '兴趣点列表',
        sheetName: '兴趣点列表',
        type: 'xlsx'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.toolsBar {
  height: 38px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
  padding-left: 5px
}

::v-deep {
  .el-tabs--border-card {
    height: 100%;
  }
  .el-tabs--border-card > .el-tabs__content {
    padding: 0 !important;
    height: calc(100% - 40px);

    .el-tab-pane {
      height: 100%;
    }
  }
}

</style>
