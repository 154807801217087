// 格式化车辆数据的字段
export function busDataFormat (node) {
  let [lng, lat] = node.lngLat ? (node.lngLat.split(',')) : [0, 0]
  lng = XEUtils.round(lng, 6)
  lat = XEUtils.round(lat, 6)
  const isOnline = !!node.onLineStat
  const speed = node.speed || 0
  const nodeFormat = {
    vehiId: node.vehiId, // 建材统一平台的车辆id
    busID: node.busID, // 设备id
    acc: !!node.acc,
    accName: node.acc === 1 ? '开' : '关',
    angle: node.angle ? 360 - node.angle : undefined,
    busLicPlate: node.busLicPlate,
    compId: node.compId,
    compName: node.compName,
    time: node.lastFixDatTim,
    lng,
    lat,
    lngLat: [lng, lat],
    lngLatStr: `${lng},${lat}`,
    isOnline,
    isOnlineStr: isOnline ? '在线' : '离线',
    ownerCode: node.ownerCode,
    weight: node.weight,
    speed,
    speedName: speed + 'km/h',
    address: '',
    mileage: node.mileage || '',
    markerIcon: getBusIcon({ isOnline, speed })
    // companyID: node.companyID,
    // deviId: node.deviId,
    // ownType: node.ownType,
    // parentBusID: node.parentBusID,
    // remark: node.remark,
    // offset: node.offset,
    // mapList: node.mapList,
    // tirePresJson: node.tirePresJson,
    // vehiStatId: node.vehiStatId,
  }
  return nodeFormat
}

// 格式化websocket推送来的车辆数据
export function formatWebsocketData (node) {
  const lng = XEUtils.round(node.dimX / 1000000, 6)
  const lat = XEUtils.round(node.dimY / 1000000, 6)
  const isOnline = true
  const speed = node.speed || 0

  return {
    lng,
    lat,
    speed,
    vehiId: node.vehiId,
    lngLat: [lng, lat],
    lngLatStr: `${lng},${lat}`,
    angle: node.angle ? 360 - node.angle : undefined,
    time: XEUtils.toDateString(node.lastFixTime),
    mileage: node.mileage,
    speedName: speed + 'km/h',
    isOnline,
    isOnlineStr: '在线',
    markerIcon: getBusIcon({ isOnline, speed })
  }
}

export function getBusIcon (node) {
  if (!node.isOnline) {
    return require('@/assets/imgs/monitor/bus_offline.png')
  }
  if (node.isOnline && node.speed <= 0) {
    return require('@/assets/imgs/monitor/bus_stop.png')
  }
  if (node.isOnline && node.speed > 0) {
    return require('@/assets/imgs/monitor/bus_running.png')
  }
  return ''
}
