<template>
  <wz-table
    ref="tableRef"
    :data="data"
    auto-resize
    height="auto"
    v-bind="$attrs"
    v-on="$listeners"
    :sort-config="{trigger: 'cell', defaultSort: {field: 'time', order: 'desc'}, orders: ['desc', 'asc', null]}"
  >
    <vxe-column type="seq" title="序号" width="60" />
    <vxe-column field="compName" title="企业/车队" minWidth="150" />
    <vxe-column field="ownerCode" title="自编号" minWidth="100" :sortable="true" />
    <vxe-column field="busLicPlate" title="车牌号" minWidth="100" :sortable="true" />
    <vxe-column title="行车图标" minWidth="80">
      <template #default="{ row }">
        <div class="flex align-center justify-center">
          <img
            v-if="row.markerIcon"
            :src="row.markerIcon"
            :style="{ transform: 'rotate(' + row.angle + 'deg)' }"
          />
        </div>
      </template>
    </vxe-column>
    <vxe-column field="speedName" title="速度" minWidth="80" :sortable="true" />
    <vxe-column field="time" title="定位时间" minWidth="135" :sortable="true"/>
    <vxe-column field="isOnlineStr" title="是否在线" minWidth="80">
      <template #default="{ row }">
        <span :class="{green: row.isOnline}">{{row.isOnlineStr}}</span>
      </template>
    </vxe-column>
    <!-- <vxe-column field="accName" title="ACC" minWidth="60">
      <template #default="{ row }">
        <span :class="{green: row.acc}">{{row.accName}}</span>
      </template>
    </vxe-column> -->
    <vxe-column field="lngLatStr" title="经纬度" minWidth="120">
      <template #default="{ row }">
        <span :class="{red: !row.lngLatStr}">{{row.lngLatStr || '无'}}</span>
      </template>
    </vxe-column>
    <vxe-column field="address" title="地址" minWidth="250" />
  </wz-table>
</template>

<script>
import { getAddress } from '@/apis/common'
export default {
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      addressTimer: null
    }
  },
  mounted () {
    this.getRowAddress()
  },
  beforeDestroy () {
    clearTimeout(this.addressTimer)
  },
  methods: {
    // 获取地址
    getAddress ({ lat, lng }) {
      return new Promise((resolve) => {
        const params = { lat, lng }
        getAddress(params).then((res) => {
          if (res.code == 200 && res.data.status == 1) {
            resolve(res)
          } else {
            console.error(res.msg)
            // this.$message.error(res.msg)
          }
        })
      })
    },

    /**
     * 获取并更新地址
     * 1. 更新列表地址
     * 2. 更新markers地址
     */
    getRowAddress () {
      this.addressTimer = setTimeout(() => {
        const visibleTableData = this.$refs.tableRef.$refs.innerTable.getTableData().tableData
        visibleTableData.forEach((row) => {
          if (!row.address && row.lngLatStr !== '0,0') {
            this.getAddress(row).then((res) => {
              // 更新地址
              this.$emit('update-address', { vehiId: row.vehiId, address: res.data.address })
              // row.address = res.data.address
            })
          }
        })
        this.getRowAddress()
      }, 3000)
    },

    activeRow (row) {
      this.$refs.tableRef.$refs.innerTable.scrollToRow(row)
      this.$refs.tableRef.$refs.innerTable.setCurrentRow(row)
    },

    exportData () {
      this.$refs.tableRef.$refs.innerTable.exportData({
        filename: '车辆列表',
        sheetName: '车辆列表',
        type: 'xlsx'
      })
    }
  }
}
</script>

<style>

</style>
