import http from '@/utils/http'

// 获取兴趣点多条
export function getPoints (p) {
  return http.post('/unibu/monitor/vehi/getPoints', p)
}

// 新增修改兴趣点
export function addPoints (p) {
  return http.post('/unibu/monitor/vehi/editPoint', p)
}

// 删除兴趣点
export function delPoint (p) {
  return http.post('/unibu/monitor/vehi/delPoint', p)
}
