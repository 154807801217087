export const pointTypes = [
  { value: 0, label: '工地' },
  { value: 1, label: '公司' },
  { value: 2, label: '停车场' },
  { value: 3, label: '消纳场' },
  { value: 10, label: '线路途经点' },
  { value: 100, label: '自动创建工地' }
]

export const picTypes = [
  { value: 0, label: '房子', img: require('@/assets/imgs/monitor/interestPoint/point_fz.png') },
  { value: 1, label: '仓库', img: require('@/assets/imgs/monitor/interestPoint/point_ck.png') },
  { value: 2, label: '车辆', img: require('@/assets/imgs/monitor/interestPoint/point_chl.png') },
  { value: 3, label: '旗子', img: require('@/assets/imgs/monitor/interestPoint/point_qzh.png') },
  { value: 4, label: '维修站', img: require('@/assets/imgs/monitor/interestPoint/point_wx.png') },
  { value: 5, label: '加油站', img: require('@/assets/imgs/monitor/interestPoint/point_jyzh.png') },
  { value: 6, label: '医院', img: require('@/assets/imgs/monitor/interestPoint/point_yy.png') },
  { value: 7, label: '维修站', img: require('@/assets/imgs/monitor/interestPoint/point_wx.png') }
]
