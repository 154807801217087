<template>
  <layout-monitor>
    <div slot="leftPane" class="flex-column p-15 h-100-p">
      <bus-tree
        ref="treeRef"
        @dblclick-node="dblClickNode"
        @actived-node="activedNode"
        @checked-node="checkedNode"
        @refresh="refreshBusList"
      />
    </div>

    <!-- 地图 -->
    <div slot="topPane" class="h-100-p">
      <BaseMap ref="mapRef" @change="handlerMapLayerChange">
        <!-- <el-button slot="prefix-action" @click="changeInfo">改变位置</el-button> -->
        <MarkerTogetherIcon slot="prefix-action" v-model="activeTogether" :layer-ins="clusterLayer"/>
      </BaseMap>
    </div>

    <!-- 列表 -->
    <div slot="bottomPane" class="table-box flex flex-column h-100-p">
      <el-tabs class="h-100-p w-100-p" type="border-card">
        <el-tab-pane label="车辆列表">
          <CarTable
            v-loading="loading"
            ref="carTable"
            :data="tableData"
            @update-address="updateRowAddress"
            @cell-dblclick="dblclickRow"
          ></CarTable>
        </el-tab-pane>
        <el-tab-pane label="兴趣点" class="tab-content">
          <InterestPointTable ref="pointTable"></InterestPointTable>
        </el-tab-pane>
      </el-tabs>
      <div class="export-btn">
        <el-dropdown @command="handlerExport" size="small">
          <span class="el-dropdown-link">
            <el-button icon="el-icon-download" type="primary">导出</el-button>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">导出车辆列表</el-dropdown-item>
            <el-dropdown-item command="2">导出兴趣点列表</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </layout-monitor>
</template>

<script>
/**
 * (存储经纬度为wgs84经纬度)
 *
 * 地图功能：
 * 1. 车辆markers展示到地图上：经纬度为null的不展示；点聚合避免大量数据卡顿；不同的地图对应各自的经纬度；
 * 2. 切换底图，修改marker的经纬度类型
 * 3. 数据更新：
 *    - 初始化一次 markers, 列表 数据更新
 *    - ws 推送 markers, 列表 数据更新
 *    - 地址更新：滚动条触发，ws推送后若经纬度有所变化重置地址为空字符串
 *    - marker info window：初始化一次，之后若有打开的info window，每2秒刷新一次info window
 *
 * 树功能：
 * 1. 选中的marker展示，未选中的隐藏掉
 *
 */
import * as maptalks from 'maptalks'
import { ClusterLayer } from 'maptalks.markercluster'
import { busDataFormat, formatWebsocketData } from './util'
import { getBusList } from '@/apis/monitor/carMonitor'
import LayoutMonitor from '../components/Layout'
import CarTable from './components/CarTable'
import BaseMap from '@/components/BaseMap'
import { formatLngLat } from '@/components/BaseMap/util'
import BusTree from '../components/BusTree'
import MarkerTogetherIcon from '@/components/BaseMap/MarkerTogetherIcon'
import InterestPointTable from './components/InterestPointTable'
import { addMessageLisner } from '@/utils/websocket'
export default {
  name: 'carMonitor',
  data () {
    return {
      // 使用Map，用空间换时间
      tableData: [],
      tableDataMap: new Map(),
      markers: [],
      markersMap: new Map(),
      loading: false,
      checkedIds: null,
      clusterLayer: null, // 散点图层
      activeMarkerId: '',
      activeTogether: true
    }
  },
  created () {
    window.closeMarkerCard = (vehiId) => {
      const markerIns = this.markersMap.get(vehiId)
      if (markerIns) {
        markerIns.closeInfoWindow()
        this.activeMarkerId = ''
      }
    }
  },
  mounted () {
    const { mapIns, layerType } = this.$refs.mapRef.init()
    this.initBusList(mapIns).then(() => {
      // 初始化websocket推送
      addMessageLisner(24, this.handlerBusMessage)
      // 初始化兴趣点
      this.$refs.pointTable.init(mapIns, layerType)
    })
  },

  methods: {
    async refreshBusList () {
      this.loading = true
      const res = await getBusList(undefined, true, false)
      this.loading = false
      if (res.code == 200) {
        const busList = res.data.map((item) => {
          const [lng, lat] = item.lngLat ? (item.lngLat.split(',')) : [0, 0]
          return {
            angle: item.angle,
            busID: item.busID,
            dimX: lng * 1000000, // 从这个接口获取的要乘1000000，因为ws处理时需要除以1000000
            dimY: lat * 1000000,
            lastFixTime: item.lastFixDatTim,
            mileage: item.mileage,
            speed: item.speed,
            vehiId: item.vehiId
          }
        })
        this.handlerBusMessage(busList)
      } else {
        this.$message.error(res.msg)
      }
    },
    // changeInfo () {
    //   const row = this.tableDataMap.get(47646)
    //   const lat = parseFloat(row.lat) + 0.0001
    //   const lng = parseFloat(row.lng) + 0.0001
    //   const node = {
    //     // 浙A5R766
    //     angle: XEUtils.random(10, 100),
    //     isOnline: true,
    //     isOnlineStr: '在线',
    //     lat,
    //     lng,
    //     lngLat: [lng, lat],
    //     lngLatStr: `${lng},${lat}`,
    //     markerIcon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAh1BMVEUDrwIAAAABXQABXQABXQABcwD+QwEBXQABXgABXQABXQABXwABXwABXQABXQACZQEClgEBYgABZQDkTgEBbwABUwEBXQABXgABXgABYwDuQgHvQgH3RAH7RQEBXwDVQAH0SAGtPQG5QAEBXwACMwIBbwACOgIBXgABXQABZwABaQD/QwEBXQBRCyRgAAAAK3RSTlMNAP35Hin1p1jk9K6od7oZEn1cVS4iuHtvYczLxMGynopza040MivZfkk/W6ei6QAAAPxJREFUKM9tkumWgyAMhWNAAfeuavd9lt73f76pYI52Tu+vJB8EuIGiIN2UjuirbFZDIQCdMwAiAJzrEVxTqKyLie5dppBaAS3jsKRBywO4DcAyz2iiGbPtgU4hdSFI9QsYZPRPGUxEmlXss8U+SfYLH8aKNRXIfXJ6ep18kqOgNeZ+/XOQ3zPHmhx8p52Ane8FRwp1HyYCkj6roV6g+gwc7n24FbCVVmU4/CLgMhwu162OoX6s5LrywNV5kySb80oeOFpSPW63RyWWeBP5l970E0yMbBu9g+jb2+51HQfVTWduWRkb13VsjUIzAdowBrHRAgIqSqeUKwv5Pn+VOxu7pVtXEAAAAABJRU5ErkJggg==',
    //     mileage: 4447000,
    //     speed: XEUtils.random(10, 100),
    //     speedName: XEUtils.random(10, 100) + 'km/h',
    //     time: XEUtils.toDateString(Date.now()),
    //     vehiId: 47646
    //   }
    //   this.updateMarkerAndList(node)
    // },
    updateRowAddress ({ vehiId, address }) {
      const row = this.tableDataMap.get(vehiId)
      row.address = address
    },

    // 处理推送来的数据
    handlerBusMessage (busList) {
      try {
        busList.forEach((busData) => {
          const newNode = formatWebsocketData(busData)
          this.updateMarkerAndList(newNode)
        })
      } catch (error) {
        console.error(error)
      }
    },

    // 要更新哪些数据:
    // 1. marker在地图中的经纬度，marker.properties中的经纬度，图标，车头方向
    // 2. 更新列表中的数据，若经纬度发生变化时，置空地址(更新了经纬度需要重新获取地址)
    updateMarkerAndList (busData) {
      const marker = this.markersMap.get(busData.vehiId)
      const layerType = this.$refs.mapRef.layerType
      const tableRow = this.tableDataMap.get(busData.vehiId)
      console.log(tableRow?.busLicPlate, ', row=>', tableRow, ', lngLat=>', tableRow?.lngLat, ', busData', busData)
      if (!marker) return
      // 更新在地图中的位置
      const position = formatLngLat(busData.lngLat, 'gps', layerType)
      marker.setCoordinates(position)

      // 更新properties中的wgs84经纬度
      const properties = marker.getProperties()
      properties.lng = busData.lng
      properties.lat = busData.lat
      marker.setProperties(properties)

      // 更新图标， 车头方向
      marker.updateSymbol([{
        markerFile: busData.markerIcon,
        markerRotation: busData.angle
      }])

      // 地图以marker为中心
      if (busData.vehiId == this.activeMarkerId) {
        marker.getMap().panTo(position)
      }

      // 更新列表数据
      if (tableRow.lngLatStr !== busData.lngLatStr) tableRow.address = ''
      Object.assign(tableRow, busData)
    },

    // 初始化busList，创建并存储marker数据，table数据
    async initBusList (mapIns) {
      this.loading = true
      const res = await getBusList()
      this.loading = false
      if (res.code === 200) {
        const markers = this.markers
        const markersMap = this.markersMap
        const tableData = this.tableData
        const tableDataMap = this.tableDataMap

        res.data.forEach((item) => {
          const busNode = busDataFormat(item)
          const markerIns = this.getMarkerIns(busNode)
          this.initMarkerWindow(markerIns, item)
          markers.push(markerIns)
          markersMap.set(busNode.vehiId, markerIns)
          tableData.push(busNode)
          tableDataMap.set(busNode.vehiId, busNode)
        })

        // 创建markers图层添加到地图中
        this.createMarkersLayerToMap(markers, mapIns)
        this.refreshMarkerWindow()

        return res
      } else {
        this.$message.error(res.msg)
      }
    },

    initMarkerWindow (markerIns, node) {
      const infoWindow = new maptalks.ui.InfoWindow({
        autoPan: false,
        autoOpenOn: 'click', // set to null if not to open when clicking on marker
        custom: true,
        width: 300,
        height: 300,
        content: this.getMarkerWindowContent(node)
      })
      markerIns.setInfoWindow(infoWindow)
      markerIns.on('click', () => {
        this.activeMarkerId = markerIns.getId()
        const row = this.tableDataMap.get(markerIns.getId())
        this.$refs.carTable.activeRow(row)
        markerIns.bringToFront()
        markerIns.getMap().panTo(markerIns.getCoordinates())
      })
    },

    refreshMarkerWindow () {
      const timer = setInterval(() => {
        if (this.activeMarkerId) {
          const markerIns = this.markersMap.get(this.activeMarkerId)
          const node = this.tableDataMap.get(this.activeMarkerId)
          markerIns.getInfoWindow().setContent(this.getMarkerWindowContent(node))
        }
      }, 2000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer)
      })
    },

    // 监听地图底图切换,修改marker经纬度类型
    handlerMapLayerChange ({ mapIns, layerType }) {
      this.markers.forEach((marker) => {
        const properties = marker.getProperties()
        const position = formatLngLat(
          [properties.lng, properties.lat],
          'gps',
          layerType
        )
        marker.setCoordinates(position)
      })
    },

    // 获取marker实例
    getMarkerIns (node) {
      const lngLat = formatLngLat(
        [node.lng, node.lat],
        'gps',
        this.$refs.mapRef.layerType
      )

      return new maptalks.Marker(lngLat, {
        id: node.vehiId, // 唯一标识 vehiId
        properties: {
          name: node.busLicPlate,
          lng: node.lng,
          lat: node.lat
        },
        symbol: [
          {
            markerFile: node.markerIcon, // marker 图标
            markerWidth: 25,
            markerHeight: 25,
            markerHorizontalAlignment: 'middle',
            markerVerticalAlignment: 'middle',
            markerRotation: node.angle
          },
          {
            textFaceName: 'sans-serif',
            textName: '{name}',
            textSize: 12,
            textDy: 26,
            textFill: '#000000',
            markerType: 'square',
            markerFill: '#E2AD2D',
            markerFillOpacity: 1,
            markerLineColor: '#000000',
            markerLineWidth: 0.4,
            markerWidth: this.getTextWidth(node.busLicPlate) + 5,
            markerHeight: 17,
            markerDy: 26,
            markerRotation: 0
          }
        ]
      })
    },

    // 创建marker图层添加到地图实例中
    createMarkersLayerToMap (markers, mapIns) {
      const clusterLayer = this.clusterLayer = new ClusterLayer('cluster', markers, {
        noClusterWithOneMarker: true,
        maxClusterZoom: 17, // 聚合
        // maxClusterRadius: 65,
        zIndex: 9,
        symbol: {
          markerType: 'ellipse',
          markerFill: {
            property: 'count',
            type: 'interval',
            stops: [
              [0, 'rgb(135, 196, 240)'],
              [9, '#1bbc9b'],
              [99, 'rgb(216, 115, 149)']
            ]
          },
          markerFillOpacity: 0.8,
          markerLineOpacity: 1,
          markerLineWidth: 3,
          markerLineColor: '#fff',
          markerWidth: {
            property: 'count',
            type: 'interval',
            stops: [
              [0, 30],
              [9, 40],
              [99, 55]
            ]
          },
          markerHeight: {
            property: 'count',
            type: 'interval',
            stops: [
              [0, 30],
              [9, 40],
              [99, 55]
            ]
          }
        },
        drawClusterText: true,
        geometryEvents: true,
        single: true,
        animation: false
      })

      this.$nextTick(() => {
        mapIns.addLayer(clusterLayer)
      })
    },

    // 隐藏未选中的车辆，展示选中的车辆
    checkedNode (checkedIds) {
      const markersMap = this.markersMap
      for (const [key, markerIns] of markersMap) {
        if (checkedIds.includes(key)) {
          markerIns.show()
        } else {
          markerIns.hide()
        }
      }
    },

    // 激活筛选的树节点：
    // 1. 树移动到该节点；2. 出发列表该行的双击事件
    activedNode (item) {
      this.dblClickNode(item)
    },

    // 点击表格行，移动至marker 并 展开信息面板
    // 双击树或表格或marker，
    dblclickRow (node) {
      const row = node.row
      const marker = this.markersMap.get(row.vehiId)
      console.log(marker, row, 'node')

      // const node = this.tableDataMap.get(row.vehiId)
      const center = marker.getCoordinates()
      if (marker) {
        this.activeMarkerId = marker.getId()
        marker.bringToFront()
        marker.getMap().animateTo({
          center,
          zoom: 18
        }, {
          duration: 400
        })
        marker.flash(200, 3, () => {
          marker.openInfoWindow()
        })
      }
    },

    // 双击树节点，移动并高亮表格行，并执行表格行的双击事件
    dblClickNode (node) {
      if (node && node.type == 'DEV') {
        const row = this.tableDataMap.get(node?.detail?.vehiId)
        if (row) {
          this.$refs.carTable.activeRow(row)
          this.dblclickRow({ row })
        }
      }
    },

    // 获取车牌生成图片后的宽度
    getTextWidth (text, font = 'normal 12px sans-serif') {
      const canvas =
        this.textCanvas || (this.textCanvas = document.createElement('canvas'))
      const context = canvas.getContext('2d')
      context.font = font
      return context.measureText(text).width
    },

    // 获取marker窗口内容
    getMarkerWindowContent (node) {
      const fields = [
        { name: '车牌号', prop: 'busLicPlate' },
        { name: '自编号', prop: 'ownerCode' },
        { name: '企业/车队', prop: 'compName' },
        { name: '速度', prop: 'speedName' },
        { name: '时间', prop: 'time' },
        { name: '地址', prop: 'address' }
      ]
      // 详情显示内容
      const html = `
        <div class="marker-card fs-12">
          <i class="marker-card-close el-icon-close fs-14" onclick="closeMarkerCard(${node.vehiId})"></i>
          <div class="flex flex-column fs-12 bgcolor-white w-100-p">
          ${
            fields.reduce((sum, curr) => {
              const value = node[curr.prop]
              return sum + `<p>${curr.name}: ${value || '-'}</p>`
            }, '')
          }
          </div>
        </div>
      `.trim()
      return html
    },

    // 导出表格
    handlerExport (type) {
      if (type === '1') {
        this.$refs.carTable.exportData()
      } else if (type === '2') {
        this.$refs.pointTable.exportData()
      }
    }
  },
  components: {
    LayoutMonitor,
    BaseMap,
    CarTable,
    BusTree,
    MarkerTogetherIcon,
    InterestPointTable
  }
}
</script>

<style lang="scss">
.el-tabs--border-card {
  border: 0 !important;
}
.el-tabs--border-card {
  height: 100%;
}
.el-tabs--border-card > .el-tabs__content {
  padding: 0 !important;
  height: calc(100% - 40px);

  .el-tab-pane {
    height: 100%;
  }
}

.tree-name {
  user-select: none;
  cursor: pointer;
  padding: 0 5px;
  &.active,
  &:hover {
    background-color: #f5f7fa;
  }
}

.table-box {
  position: relative;
}
.export-btn {
  position: absolute;
  top: 5px;
  right: 20px;
}

.wazert-tab-item {
  &.active {
    color: #fff;
  }
}
</style>
